import { colours } from '../constants';

const darkTheme = {
  name: 'dark',
  body: colours.darkBlueBlack,
  text: colours.offWhite,
  toggleBorder: '#6B8096',
  background: '#999',
  buttonBackground: colours.lightPurpleGrey,
  buttonText: colours.darkBlueBlack,

  codeHighlightBackground: colours.offWhite,
  codeHighlightText: colours.midGrey,

  tableOfContentsBorder: colours.offWhite,

  // Footer
  footerBackground: colours.darkBlueBlack,
  copyrightBackground: colours.lightDarkBlueBlack,
  footerText: colours.offWhite,

  // Theme Toggle
  themeToggleButtonBackground: colours.lightPurpleGrey,
  themeToggleIcon: colours.darkBlueBlack,
  themeToggleBackground: colours.darkBlueBlack,
  themeToggleText: colours.lightPurpleGrey,

  // Placeholders
  placeholderBackground: colours.lightBlueBlack,

  // Bottom Navigation Bar
  navbarToggleBackground: colours.lightPurpleGrey,
  navbarToggleText: colours.darkBlueBlack,
  navbarBackground: colours.darkBlueBlack,
  navbarText: colours.lightPurpleGrey,
}

export default darkTheme;