import styled, { css } from "styled-components";

const Neumorphic = css`
  box-shadow: 2px 2px 3px #00000040, // bottom right
              inset 1px 1px 1px #ffffff0a, // top left edge
              inset 5px 5px 10px #ffffff05; // top left shine
`;

const NeumorphicInset = css`
  box-shadow: inset 1px 1px 3px #00000040, 
              inset -50px -50px 25px #ffffff40;
`;

const ToggleContainer = styled.div`
  ${NeumorphicInset}

  display: flex;
  flex-direction: ${props => (props.toggled ? "row-reverse" : "row")};
  align-items: center;
  border-radius: 50px;
  padding: 5px 7px;
  height: 40px;
  width: 100px;
  background-color: ${props => props.theme.themeToggleBackground};

  position: absolute;
  top: 25px;
  right: 25px;

  @media (max-width: 768px) {
    left: 25px;
    right: unset;
  }

  z-index: 5;
`;

const ToggleButton = styled.a`
  ${Neumorphic}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  color: ${props => props.theme.themeToggleIcon};
  background-color: ${props => props.theme.themeToggleButtonBackground};
  width: 32px;
  height: 32px;
  padding: 10px;
  box-sizing: border-box;
`;

const ToggleText = styled.p`
  text-transform: uppercase;
  margin: 0;
  padding: 0 10px;
  color: ${props => props.theme.themeToggleText};
`;

export {
  ToggleContainer,
  ToggleButton,
  ToggleText,
};