import { useState, useLayoutEffect } from "react";
import themes from "../themes";
import useLocalStorage from "./useLocalStorage";

/**
 * A custom hook for managing the application's current theme.
 * Uses localStorage to persist the theme across sessions.
 * 
 * @returns {[object, function]} The current theme and a function to toggle the theme.
 */
const useThemes = () => {
    const defaultTheme = themes.light;
    const localStorageOptions = { 
        key: "theme",
        initialValue: defaultTheme.name
    };

    const [themeName, setThemeName] = useLocalStorage(localStorageOptions);
    const [theme, setTheme] = useState(defaultTheme);

    useLayoutEffect(() => {
        setTheme(themes[themeName]);
    }, [themeName]);

    const toggleTheme = () => {
        themeName === themes.light.name 
            ? setThemeName(themes.dark.name) 
            : setThemeName(themes.light.name);
    };

    return [theme, toggleTheme];
};

export default useThemes;