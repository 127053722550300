import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet';
import GlobalStyle from '../styles/global-style';
import Footer from '../footer';
import Navbar from '../navbar';
import ThemeToggle from '../theme-toggle';
import { useThemes } from '../../../hooks';
import styled, { ThemeContext } from 'styled-components';

// below resolves issue with font-awesome icons not rendering correctly initially
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

const siteMetadataQuery = graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 40%;
  margin: 150px auto;
  padding: 25px;
  box-sizing: border-box;
  min-height: 100vh;
  
  @media (max-width: 1800px) {
    width: 60%;
  }

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    margin-top: 100px;
    width: 90%;
  }
`;

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(siteMetadataQuery);
  const title = pageTitle
    ? `${pageTitle} | ${data.site.siteMetadata.title}` 
    : data.site.siteMetadata.title;

  const [theme, toggleTheme] = useThemes();

  return (
    <Fragment>
      <Helmet
        title={title}
      />

      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        
        <GlobalStyle theme={theme} />
        
        <Navbar />
        <ThemeToggle />
        
        <MainContainer >
          {children}
        </MainContainer>

        <Footer />
      </ThemeContext.Provider>
    </Fragment>
  );
};

export default Layout