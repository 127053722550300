import React, { useState, useContext, useEffect } from 'react';
import * as S from './bottom-navbar.styles';
import { ThemeContext, ThemeProvider } from 'styled-components';
import { faChevronRight, faBars } from '@fortawesome/free-solid-svg-icons';

const BottomNavbar = ({ navItems, location }) => {
  const { theme } = useContext(ThemeContext);
  const [isOpened, setIsOpened] = useState();

  useEffect(() => {
    setIsOpened(false);
  }, [location]);

  const toggleNavbar = () => {
    setIsOpened(!isOpened);
  };

  return (
    <ThemeProvider theme={theme}>
      <S.FixedBottomNavbarContainer>
        <S.NavItems isOpened={isOpened}>
          {
            navItems.map((navItem) => {
              return (
                <S.NavItem key={navItem.name}>
                  <S.NavLink to={navItem.to}>
                    <S.NavLinkIcon icon={navItem.icon} />
                    <S.NavLinkText>{navItem.label}</S.NavLinkText>
                  </S.NavLink>
                </S.NavItem>
              );
            })
          }
        </S.NavItems>
        <S.NavToggleContainer onClick={toggleNavbar} isOpened={isOpened}>
          <S.NavToggle isOpened={isOpened}>
            {
              isOpened
                ? <S.NavToggleIcon icon={faChevronRight} />
                : <S.NavToggleIcon icon={faBars} />
            }
          </S.NavToggle>
        </S.NavToggleContainer>
      </S.FixedBottomNavbarContainer>
    </ThemeProvider>
  );
};

export default BottomNavbar;