import styled from 'styled-components';
import InternalButton from '../../buttons';

const NavbarContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  width: 150px;
  height: 60px;
  filter: ${({ theme }) => {
    return theme.name === "dark"
      ? "brightness(0) saturate(100%) invert(77%) sepia(40%) saturate(378%) hue-rotate(203deg) brightness(101%) contrast(95%)"
      : "brightness(0) saturate(100%) invert(4%) sepia(5%) saturate(3398%) hue-rotate(163deg) brightness(96%) contrast(96%)"
  }};
`;

const NavItems = styled.ul`
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
  height: 60px;

  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  display: flex;
  padding: 10px;
  min-width: 100px;
  color: ${({ theme }) => theme.buttonText};
`;

const NavItemLink = styled(InternalButton)`
  width: 100%;
`;

export {
  NavbarContainer,
  NavItems,
  NavItem,
  NavItemLink,
  LogoContainer,
};