import React from "react";
import * as S from "./footer.styles";

const CoffeeButton = () => {
  return (
    <S.CoffeeButtonContainer>
        <p>Want to show your support?</p>
        <a href="https://www.buymeacoffee.com/theandrewgrass" target="_blank" rel="noopener noreferrer">
            <S.CoffeeButtonImage role="img" aria-label="Buy me a coffee" />
        </a>
    </S.CoffeeButtonContainer>
);
};

export default CoffeeButton;