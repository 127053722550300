import * as S from "./theme-toggle.styles";
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from "styled-components";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import themes from "../../../themes";


const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [toggled, setToggled] = useState();

  useEffect(() => {
    setToggled(theme.name === themes.dark.name);
  }, [theme]);

  const toggle = () => {
    toggleTheme();
    setToggled(!toggled);
  };

  return (
    <S.ToggleContainer theme={theme} toggled={toggled}>
      <S.ToggleButton onClick={toggle} theme={theme}>
        {
          toggled
            ? <FontAwesomeIcon icon={faMoon} />
            : <FontAwesomeIcon icon={faSun} />
        }
      </S.ToggleButton>
      <S.ToggleText theme={theme}>{theme.name}</S.ToggleText>
    </S.ToggleContainer>
  );
};


export default ThemeToggle;