import { colours } from "../constants";

const lightTheme = {
    name: 'light',
    body: colours.whiteWhite,
    text: colours.darkBlueBlack,
    buttonBackground: colours.darkBlueBlack,
    buttonText: colours.lightPurpleGrey,

    codeHighlightBackground: colours.midGrey,
    codeHighlightText: colours.offWhite,

    tableOfContentsBorder: colours.darkBlueBlack,

    // Footer
    footerBackground: colours.darkBlueBlack,
    copyrightBackground: colours.lightDarkBlueBlack,
    footerText: colours.offWhite,

    // Theme Toggle
    themeToggleButtonBackground: colours.darkBlueBlack,
    themeToggleIcon: colours.lightPurpleGrey,
    themeToggleBackground: colours.whiteWhite,
    themeToggleText: colours.darkBlueBlack,

    // Placeholders
    placeholderBackground: colours.lightGrey,

    // Bottom Navigation Bar
    navbarToggleBackground: colours.darkBlueBlack,
    navbarToggleText: colours.lightPurpleGrey,
    navbarBackground: colours.whiteWhite,
    navbarText: colours.darkBlueBlack,
    navbarBorder: colours.lightPurpleGrey,
}

export default lightTheme;