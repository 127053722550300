import { useState, useLayoutEffect } from "react";

/**
 * A custom hook for storing and retrieving data from localStorage.
 * 
 * @returns {[object, function]} The current value for the given key and a function to update the value.
 */
const useLocalStorage = ({ key, initialValue }) => {
    const [storedValue, setStoredValue] = useState(initialValue);

    useLayoutEffect(() => {
        setStoredValue(window.localStorage.getItem(key) ?? initialValue);
    }, [key, initialValue]);

    const setValue = (value) => {
        setStoredValue(value);
        window.localStorage.setItem(key, value);
    };

    return [storedValue, setValue];
};

export default useLocalStorage;