import styled, { css } from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 0 0;
  box-sizing: border-box;
  text-align: center;
  gap: 30px;
  min-height: 400px;
  color: ${props => props.theme.footerText};
  background: linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%),
              ${props => props.theme.footerBackground};
`;

const FooterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 25px 0 50px 0;
  flex: 1;
`;

const CopyrightContainer = styled.div`
  width: 100%;
  padding: 30px;
  background: linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%)
              ${props => props.theme.copyrightBackground};
  box-sizing: border-box;
  justify-self: flex-end;
`;

const CopyrightText = styled.p`
  margin: 0;
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SocialsList = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
  gap: 20px;
  margin: 0;
`;

const SocialsItem = styled.li`
`;

const Neumorphic = css`
  box-shadow: 2px 2px 3px #00000040, // bottom right
              inset 1px 1px 1px #ffffff0a, // top left edge
              inset 5px 5px 10px #ffffff05; // top left shine
`;

const NeumorphicInset = css`
  box-shadow: inset 2px 2px 3px #00000060,
              inset -10px -30px 25px #ffffff05;
`;

const SocialsButton = styled.a`
  ${Neumorphic}

  display: inline-flex;
  border-radius: 50px;
  padding: 20px;
  font-size: 1.5rem;
  color: ${props => props.theme.footerText};
  background: linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.05) 100%);

  &:hover {
    ${NeumorphicInset}
  }
`;

const CoffeeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const CoffeeButtonImage = styled.div`
  ${Neumorphic}

  height: 60px;
  width: 217px;
  border-radius: 30px;
  background: linear-gradient(135deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.025) 100%),
              url(https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png);
  background-size: contain;

  &:hover {
    ${NeumorphicInset}
  }
`;

const SectionDivider = styled.hr`
  border: 0.25px solid #ebebeb;
  color: #ebebeb;
  width: 100%;
`;

export {
  FooterContainer,
  FooterContentContainer,
  CopyrightContainer,
  CopyrightText,
  SocialsContainer,
  SocialsList,
  SocialsItem,
  SocialsButton,
  CoffeeButtonContainer,
  CoffeeButtonImage,
  SectionDivider,
};