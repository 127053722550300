const colours = {
  superLightPurpleGrey: '#D1CFE3',
  lightPurpleGrey: '#D0CCFB',
  lightDarkBlueBlack: '#0F1316',
  lightBlueBlack: '#1E2226',
  darkBlueBlack: '#0B0F12',
  // whites
  whiteWhite: '#FFFFFF',
  offWhite: '#E4E3EF',
  // pure greys
  lightGrey: '#E7E7E7',
  midGrey: '#2f2f2f',
};

export default colours;