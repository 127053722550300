import React, { Fragment } from "react";
import TopNavbar from "./top-navbar";
import BottomNavbar from "./bottom-navbar";
import data from "./navbar-data";

const Navbar = () => {
  const navItems = data.navItems;
  return (
    <Fragment>
        <TopNavbar navItems={navItems} />
        <BottomNavbar navItems={navItems} />
    </Fragment>
  );
};

export default Navbar;