import React, { useContext } from "react";
import * as S from "./footer.styles";
import Copyright from "./copyright";
import Socials from "./socials";
import { ThemeContext } from "styled-components";
import CoffeeButton from "./coffee-button";

const Footer = () => {
  const { theme } = useContext(ThemeContext);
  // there's gotta be a better way than a bunch of theme props but just for now
  return (
    <S.FooterContainer theme={theme}>
      <S.FooterContentContainer>
        <Socials theme={theme} />
        <S.SectionDivider />
        <CoffeeButton />
      </S.FooterContentContainer>

      <Copyright theme={theme} />
    </S.FooterContainer>
  )
};

export default Footer;