import React from "react";
import * as S from "./footer.styles";

const Copyright = () => {
  const getCopyrightYearRange = () => {
    const startingYear = 2021;
    const currentYear = new Date().getFullYear();
    return currentYear === startingYear 
      ? currentYear.toString() 
      : `${startingYear} - ${currentYear}`;
  }

  return (
    <S.CopyrightContainer>
      <S.CopyrightText>
        <i>
          <span>Copyright © {getCopyrightYearRange()} </span>
          <span><b>Andrew Grass </b></span>
          <span>All rights reserved.</span>
        </i>
      </S.CopyrightText>
    </S.CopyrightContainer>
  );
};

export default Copyright;