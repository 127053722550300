import { faTwitter, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const socialsData = [
  {
    title: "Twitter",
    link: "https://twitter.com/theandrewgrass",
    icon: faTwitter,
  },
  {
    title: "Github",
    link: "https://github.com/theandrewgrass",
    icon: faGithub,
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/andrew-grass",
    icon: faLinkedin,
  },
  {
    title: "Email",
    link: "mailto:andygrass92@gmail.com",
    icon: faEnvelope,
  },
];