import React, { useContext } from "react";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import { Link } from "gatsby";

const ButtonContainer = styled.div`
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  border: none;

  box-shadow: 2px 2px 3px #00000040, // bottom right
  inset 1px 1px 1px #ffffff0a, // top left edge
  inset 5px 5px 10px #ffffff05; // top left shine

  &:hover {
  box-shadow: inset 1px 1px 3px #00000040,
        inset -10px -30px 25px #ffffff40;
  }

  color: ${({ theme }) => theme.buttonText};

  background: linear-gradient(135deg, rgba(255,255,255,0.025) 0%, rgba(0,0,0,0.01) 100%), 
              ${({ theme }) => theme.buttonBackground};
`;

const ButtonLink = styled(Link)`
  display: block;
  padding: 10px;
`;

const InternalButton = ({ className, to, label }) => {
  const { theme } = useContext(ThemeContext);
  
  return (
    <ThemeProvider theme={theme}>
      <ButtonContainer 
        className={className}
      >
        <ButtonLink to={to}>
          {label}
        </ButtonLink>
      </ButtonContainer>
    </ThemeProvider>
  );
};

export default InternalButton;