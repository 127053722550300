import React from "react";
import * as S from "./footer.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { socialsData } from "./socials-data";

const Socials = () => {
  return (
    <S.SocialsContainer>
      <p>Feel free to reach out!</p>
      
      <S.SocialsList>
        {socialsData.map((social) => (
          <S.SocialsItem key={social.title}>
            <S.SocialsButton 
              href={social.link} 
              title={social.title}
              aria-label={social.title}
              target="_blank" rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={social.icon} />
            </S.SocialsButton>
          </S.SocialsItem>
        ))}
      </S.SocialsList>
    </S.SocialsContainer>
  );
};

export default Socials;