import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

const FixedBottomNavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 25px;
  z-index: 100;
  width: 100%;
  overflow: hidden;
  margin-left: 5px;

  right: 0;
  height: 75px;
  pointer-events: none;

  @media (min-width: 768px) {
    display: none;
  }
`;

const NavItems = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0 0 3px 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  overflow: hidden;

  transition: transform 0.5s ease-in-out;    

  transform: ${props => props.isOpened ? 'translateX(0)' : 'translateX(calc(100% + 50px))'};
  z-index: 2;

  box-shadow: 2px 2px 3px #00000040, // bottom right
  inset 1px 1px 1px #ffffff0a, // top left edge
  inset 5px 5px 10px #ffffff05; // top left shine
`;

const NavItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    background: linear-gradient(135deg, rgba(255,255,255,0.025) 0%, rgba(0,0,0,0.01) 100%), 
                ${({ theme }) => theme.navbarBackground};
    border-right: 1px solid ${({ theme }) => theme.navbarBorder};
`;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  text-decoration: none;
  color: ${({ theme }) => theme.navbarText};
  padding: 15px 25px;
  box-sizing: border-box;
  width: 100%;

  pointer-events: auto;
`;

const NavLinkIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  z-index: 2;
`;

const NavLinkText = styled.p`
  margin: 0;
  text-transform: uppercase;
  z-index: 2;
`;

const NavToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, rgba(255,255,255,0.025) 0%, rgba(0,0,0,0.1) 100%),
              ${({ theme }) => theme.navbarToggleBackground};
  padding: 2px;
  transition: border-radius 0.5s ease-in-out;

  border-top-left-radius: ${({ isOpened }) => isOpened ? 0 : "50px"};
  border-bottom-left-radius: ${({ isOpened }) => isOpened ? 0 : "50px"};
  margin-bottom: 3px;
  margin-left: -1px;
  z-index: 5;

  box-shadow: 2px 2px 3px #00000040, // bottom right
  inset 1px 1px 1px #ffffff0a, // top left edge
  inset 5px 5px 10px #ffffff05; // top left shine

  &:hover {
      cursor: pointer;
  }

  box-sizing: border-box;
`;

const NavToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0 7px 0 10px;
  border-radius: 50%;
  color: ${({ theme }) => theme.navbarToggleText};
  pointer-events: auto;
`;

const NavToggleIcon = styled(FontAwesomeIcon)`
`;

export {
  FixedBottomNavbarContainer,
  NavItems,
  NavItem,
  NavLink,
  NavLinkIcon,
  NavLinkText,
  NavToggleContainer,
  NavToggle,
  NavToggleIcon,
};