import * as S from "./top-navbar.styles";
import React, { useContext } from "react";
import { Link } from "gatsby";
import { ThemeContext, ThemeProvider } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const TopNavbar = ({ navItems }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <ThemeProvider theme={theme}>
      <S.NavbarContainer>
        <nav>
          <S.NavItems>
            <S.NavItem>
              <S.LogoContainer>
                <Link to="/" style={{ width: "100%", height: "100%", display: "block" }}>
                  <StaticImage 
                    src="../../../../images/ag_icon.svg" 
                    alt="ag-icon"
                    style={{ width: "100%", height: "100%" }}
                    placeholder="tracedSVG"
                  />
                </Link>
              </S.LogoContainer>
            </S.NavItem>

            {
              navItems.map((navItem) => {
                return (
                  <S.NavItem key={navItem.name}>
                    <S.NavItemLink 
                      to={navItem.to} 
                      label={navItem.label} 
                    />
                  </S.NavItem>
                );
              })
            }
          </S.NavItems>
        </nav>
      </S.NavbarContainer>
    </ThemeProvider>
  );
};

export default TopNavbar;