import { faGlobeAmericas, faFeatherAlt } from "@fortawesome/free-solid-svg-icons";

const data = {
  navItems: [
    {
      name: 'home',
      label: 'Home',
      icon: faGlobeAmericas,
      to: '/',
    },
    {
      name: 'blog',
      label: 'Blog',
      icon: faFeatherAlt,
      to: '/blog',
    },
    // {
    //   name: 'projects',
    //   label: 'Projects',
    //   icon: faUserAstronaut,
    //   to: '/projects',
    // }
  ],
};

export default data;